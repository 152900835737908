// extracted by mini-css-extract-plugin
export var container = "styles-module--container--dbO9k";
export var body = "styles-module--body--fY5FE";
export var promotion = "styles-module--promotion--1YtGb";
export var formContainer = "styles-module--form-container--28YsQ";
export var formTitle = "styles-module--form-title--3KQlM";
export var form = "styles-module--form--1t_e5";
export var button = "styles-module--button--1rLYj";
export var formMessage = "styles-module--form-message--3jwS4";
export var title = "styles-module--title--2_LGY";
export var mdx = "styles-module--mdx--1h7kC";
export var brandListTitle = "styles-module--brand-list-title--1igRM";
export var brandList = "styles-module--brand-list--1I6r0";
export var brand = "styles-module--brand--2_6ny";