import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { SEO, Input, Promotion, Icon, Container } from '@components';

import * as s from './styles.module.scss';

interface IServicePageQuery {
    mdx: {
        body: string;
        frontmatter: {
            title: string;
            description: string;
            featured: {
                alt: string;
                src: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            brands: ReadonlyArray<{
                name: string;
                type: string;
                url: string;
                src: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            }>;
        };
    };
}

function Service({ data }: PageProps<IServicePageQuery>) {
    const [status, setStatus] = React.useState('');
    const [state, setState] = React.useState({
        'form-name': 'contact',
        'bot-field': '',
        name: '',
        email: '',
        phone: '',
        address: '',
        message: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setState({ ...state, [event.target.name]: event.target.value });

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (status === 'SUCCESS') {
            return;
        }

        grecaptcha.ready(() => {
            grecaptcha.execute(window.GRECAPTCHA_KEY as string, { action: 'contact' }).then((token) => {
                const body = Object.entries({ ...state, 'g-recaptcha-response': token })
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');

                fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body,
                }).then(({ status: statusCode }) => {
                    if (statusCode === 200) {
                        setStatus('SUCCESS');
                    }
                    setState({
                        ...state,
                        name: '',
                        email: '',
                        phone: '',
                        address: '',
                        message: '',
                    });
                });
            });
        });
    };

    return (
        <Container className={s.container}>
            <SEO title={data.mdx.frontmatter.title} />
            <div className={s.body}>
                <h1 className={s.title}>{data.mdx.frontmatter.title}</h1>
                <GatsbyImage
                    image={data.mdx.frontmatter.featured.src.childImageSharp.gatsbyImageData}
                    alt={data.mdx.frontmatter.featured.alt}
                />
                <div className={s.promotion}>
                    <Promotion
                        link="https://apply.svcfin.com/home/dealerAuthentication?id=815126916&key=1742219857"
                        linkName="Apply Now"
                        icon={<Icon name="finance" />}
                    >
                        Let your dream become reality. Enjoy deferred or no interest on your next project with a
                        qualified loan.
                    </Promotion>
                </div>
                <div className={s.mdx}>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </div>
                {data.mdx.frontmatter.brands && (
                    <>
                        <h3 className={s.brandListTitle}>Manufacturers</h3>
                        <div className={s.brandList}>
                            {data.mdx.frontmatter.brands.map((brand) => (
                                <a className={s.brand} href={brand.url}>
                                    <GatsbyImage image={brand.src.childImageSharp.gatsbyImageData} alt={brand.name} />
                                    <p>{brand.type}</p>
                                </a>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className={s.formContainer}>
                <h3 className={s.formTitle}>Request a Free Estimate</h3>
                <form
                    className={s.form}
                    name="contact"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-netlify-recaptcha="true"
                    onSubmit={handleFormSubmit}
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <input id="bot-field" name="bot-field" value={state['bot-field']} onChange={handleInputChange} />
                    <div>
                        <Input
                            type="text"
                            title="First and Last Name"
                            name="name"
                            placeholder="Name"
                            value={state.name}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="email"
                            title="Email Address"
                            name="email"
                            placeholder="Email"
                            value={state.email}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="tel"
                            title="Primary Phone Numbers"
                            name="phone"
                            placeholder="Phone Number"
                            value={state.phone}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="text"
                            title="Project Zip Code Or Address"
                            name="address"
                            placeholder="Zip Code or Address"
                            value={state.address}
                            onChange={handleInputChange}
                        />
                        <Input
                            type="textarea"
                            title="Message"
                            name="message"
                            placeholder="What can we help you with?"
                            value={state.message}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button className={s.button} type="submit">
                        Send message
                    </button>
                    {status === 'SUCCESS' && (
                        <div className={s.formMessage}>Thank you. We will get back to you as soon as possible.</div>
                    )}
                </form>
            </div>
        </Container>
    );
}

export default Service;

export const pageQuery = graphql`
    query ServiceQuery($slug: String!) {
        mdx(slug: { eq: $slug }) {
            body
            frontmatter {
                title
                description
                featured {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                aspectRatio: 2.5
                                layout: CONSTRAINED
                                width: 800
                                transformOptions: { cropFocus: CENTER }
                            )
                        }
                    }
                }
                brands {
                    name
                    type
                    url
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                aspectRatio: 1
                                layout: CONSTRAINED
                                width: 150
                                transformOptions: { fit: CONTAIN }
                                backgroundColor: "#ffffff"
                            )
                        }
                    }
                }
            }
        }
    }
`;
